// BLOCS & COMPONENTS

// BOUTONS ///

.btn {
    margin: auto;
    font-family: @font;
    font-size: 1.8rem;
    font-weight: 700;
    padding-left: 15px;
    min-height: 34px;
    height: 34px;
    text-align: center;
    border: 1px solid transparent;
    .flex-i(row,center,center);
    .rounded(4px);

    @media print {
        display: none;
    }

    span {
        margin-right: 10px;
    }

    i {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 2.3rem;

        .only-icon& {
            margin: 0;
            font-size: 4.5rem;
        }
    }

    &.no-icon {
        padding-right: 15px;
    }

    &.only-icon {
        padding-right: 15px;
    }

    &.small {
        height: 30px;
        min-height: 30px;
        font-size: 1.6rem;
        padding-left: 8px;

        span {
            margin-right: 0;
        }

        i {
            margin: 0;
        }
    }

    &.big {
        height: 44px;
        min-height: 44px;
    }
} // .btn

.btn-primary {
    border-color: @color;
    background-color: @color;
    color: #fff;

    &:hover {
        background-color: transparent;
        color: @color;
    }
}

.btn-secondary {
    border-color: @color2;
    background-color: @color2;
    color: #fff;

    &:hover {
        background-color: transparent;
        color: @color2;
    }
}

.btn-tertiary {
    border-color: @color3;
    background-color: @color3;
    color: #fff;

    &:hover {
        background-color: transparent;
        color: @color3;
    }
}

.btn-fourty {
    border-color: @color4;
    background-color: @color4;
    color: #fff;

    &:hover {
        background-color: transparent;
        color: @color4;
    }
}

.btn-back {
    display: flex;
    max-width: 100px;
}

//// SHARER
.sharer {
    .flex(row,center,flex-start);

    a {
        position: relative;
        margin-bottom: 0;
        color: @color;
        margin-right: 8px;

        i {
            font-size: 2.7rem;
        }

        span {
            position: absolute;
            color: #fff;
            top: -30px;
            left: 50%;
            margin-left: -45px;
            opacity: 0;
            width: 90px;
            height: 25px;
            font-size: 1.5rem;
            z-index: -1;
            .transition;
            .flex(row,center,center);

            &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 50%;
                margin-left: -7px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 7px 0 7px;
            }
        }

        &:hover {
            span {
                z-index: 99;
                opacity: 1;
                .translateY(-5px);
            }
        }

        /* stylelint-disable */
        each(@socials, {
            &.share-@{key} {
                span {
                    background-color: @value;

                    &::after {
                        border-color: @value transparent transparent transparent;
                    }
                }

                &:hover {
                    i {
                        color: @value;
                    }
                }
            }
        })
        /* stylelint-enable */

    }
} // .sharer

.reassurance {
    .flex(row,flex-start,center);

    @media @laptop {
        flex-wrap: wrap;
    }

    li {
        margin: 0 25px;
        line-height: 1.1;
        .flex(column,center,flex-start);
    }

    span {
        text-align: center;
        font-size: 1.8rem;
    }

    i {
        font-size: 3rem;
        color: @color;

        &.icon-truck {
            font-size: 2.4rem;
            padding-left: 2px;
        }

        &::before {
            height: 80px;
            min-width: 80px;
            .flex(row,center,center);
        }

        &::after {
            font-size: 8rem;
        }
    }

    strong {
        display: block;
        color: @color;
    }

    .txt {
        font-size: 1.5rem;
        color: @grey;
        display: block;
        max-width: 180px;
        .flex(column,center,center);

        &::before {
            content: '';
            background-color: @grey;
            height: 1px;
            width: 66px;
            margin: 20px 0;
        }
    }
} // .reassurance

//// POPUP /////
body {
    &.mode-popup {
        overflow: hidden;

        &::after {
            display: block;
            content: '';
            position: fixed;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            background-color: fadeout(@color, 15%);
            z-index: 9998;
        }
    }
}

.popup {
    display: none;
    color: #333;
    position: fixed;
    top: 8%;
    bottom: 8%;
    left: 50%;
    height: auto;
    width: 90%;
    max-width: 790px;
    margin-left: -395px;
    z-index: 9999;
    overflow-y: auto;

    @media screen and (max-width: 880px) {
        /* 640 */
        left: 5%;
        right: 5%;
        top: 5%;
        bottom: 5%;
        width: auto;
        max-width: 90%;
        margin-left: 0;
    }

    @media @mobile {
        /* 640 */
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        max-width: 100%;
    }

    .popup-heading {
        background-color: @color3;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.8rem;
        padding: 0 15px;
        letter-spacing: 0.15rem;
        height: 50px;
        margin-bottom: 0;
        .flex(row,center,flex-start);

        @media @mobile {
            height: auto;
            min-height: 50px;
            padding: 10px 40px;
            line-height: 1;
        }
    }

    .popup-content {
        background-color: #fff;
        padding: 15px;
        padding-top: 25px;
    }

    .cols {
        display: flex;
        justify-content: space-between;

        > div {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    input[type="email"] {
        min-width: 300px;
    }

    &.open {
        display: block;
    }

    .close-popup {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;

        i {
            font-size: 3.5rem;
        }

        &:hover {
            color: @color;
        }

        @media @mobile {
            position: fixed;
        }
    }
} // .popup

.pagination {
    margin-bottom: 80px;
    .flex(row,center,center);

    li {
        margin: 0 15px;

        &.active,
        &:hover {
            a {
                color: #fff;
                background-color: @color;
            }
        }

        &:only-child {
            display: none;
        }
    }

    a {
        width: 28px;
        height: 28px;
        font-size: 1.6rem;
        color: @color;
        background-color: @grey-light;
        .flex(row,center,center);
        .rounded(10px);
    }
} // .pagination

.bubble {
    height: 30px;
    width: 30px;
    background-color: @color3;
    color: #fff;
    .flex(row,center,center);
    .rounded(50%);
}

// BLOC FEATURED
.bloc-featured {
    padding: 0;
    max-width: 1300px;
    margin-bottom: 90px;
    text-align: center;
    border: 1px solid #ddd;
    .rounded(15px);

    @media @laptop {
        max-width: 600px;
    }

    > .heading {
        background-color: #fff;
        margin-top: -90px;
        margin-left: 60px;
        margin-bottom: 40px;
        max-width: 450px;

        @media @mobile {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .product-list {
        @media @laptop {
            grid-template-columns: 1fr 1fr;
        }

        @media @mobile {
            grid-template-columns: 1fr;
        }
    }
}

.featured-bottom {
    background-color: #f3f3f3;
    padding: 30px 60px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    @media @desktop {
        padding: 30px 20px;
    }

    .heading {
        margin-bottom: 40px;
    }
}

.breadcrumb {
    font-size: 1.2rem;
    color: @grey;
    padding-left: 160px;
    margin-bottom: 25px;

    @media @desktop {
        padding-left: 15px;
    }

    a {
        color: @color3;

        &:hover {
            text-decoration: underline;
        }
    }
}

// TABLE
.table-list {
    margin-bottom: 25px;
    width: 100%;

    th {
        text-transform: uppercase;
        height: 50px;
        vertical-align: middle;
        color: @black;
        text-align: left;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: tint(@color3, 85%);
            }
        }
    }

    td {
        vertical-align: middle;
        height: 50px;
        padding: 15px;
        line-height: 1;

        &.ref {
            width: 140px;
        }

        &.action {
            text-align: right;
            width: 230px;
        }
    }

    a {
        color: @color3;
        .flex-i(row,center,flex-start);

        i {
            font-size: 2.7rem;
            margin-left: 5px;
        }

        &:hover {
            color: shade(@color3, 50%);
        }
    }
} // .table-list

// NAV ONGLET
.nav-onglet {
    margin-bottom: 40px;
    .flex(row,center,center);

    @media @xmobile {
        flex-direction: column;
    }

    a {
        min-height: 34px;
        padding: 0 20px;
        margin: 0 10px;
        background-color: @grey-light;
        color: @grey;
        .flex(row,center,center);
        .rounded(10px);

        @media @xmobile {
            margin-bottom: 10px;
        }

        &.actif,
        &:hover, {
            background-color: @color4;
            color: #fff;
        }

        &:only-child {
            display: none;
        }
    }
} // .nav-onglet

.onglet-content {
    display: none;

    h2 {
        font-size: 2.7rem;
        margin-bottom: 20px;
    }

    &.open {
        display: block;
    }
}

// MESSAGES INFO
.msg-info {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    min-height: 50px;
    text-align: left;
    margin-bottom: 40px;
    .rounded(15px);

    &:empty {
        display: none;
    }

    i {
        font-size: 4rem;
        font-style: normal;
        margin-right: 15px;
    }
}

.valid {
    background-color: @valid;
    color: #fff;
}

.error {
    background-color: @alert;
    color: #fff;
}

.warning {
    background-color: @color3;
    color: #fff;
}

// FAST CONTACT
.fast-contact {
    max-width: 295px;
    z-index: 5000;
    position: fixed;
    top: 22%;
    right: 0;
    color: #fff;
    background-color: @color;
    border-radius: 15px 0 0 15px;
    .box-shadow(0, 0, 25px, 0.4);

    @media @mobile {
        top: 100px;
    }

    &.is-open {
        padding: 30px 15px;

        .form-fast-contact {
            display: flex;
        }

        .toggle-fast-contact {
            box-shadow: none;

            &::before {
                content: @icon-close;
            }

            &:hover {
                background-color: @color;
                color: @color3;
            }
        }
    }

    .h4 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 15px;
    }

    em {
        font-size: 1.2rem;
    }

    input {
        width: 100%;
    }

    form {
        margin-bottom: 20px;
    }

    .checkbox label {
        color: #fff;
    }
}

.toggle-fast-contact {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    color: #fff;
    font-size: 3rem;
    background-color: @color;
    border-radius: 15px 0 0 15px;
    cursor: pointer;
    .box-shadow(0, 0, 20px, 0.1);
    .flex(row,center,center);

    &:hover {
        background-color: @color3;
    }
}

.form-fast-contact {
    display: none;
    flex-direction: column;
}

// REMODAL
.remodal-overlay {
    background-color: fadeout(#fff, 10%);
}

.remodal {
    background-color: @color;
    color: #fff;
    .rounded(15px);

    @media @mobile {
        padding: 45px 15px 15px 15px;
    }

    label {
        color: #fff;
    }

    h2,
    .h2 {
        color: #fff;
    }

    .remodal-close,
    .remodal-close::before {
        left: auto;
        right: 5px;
        top: 5px;
        font-size: 50px;
        color: #fff;
    }

    .desc {
        margin-bottom: 20px;
        text-align: left;
    }
}

.remodal-heading {
    margin-bottom: 30px;
}

// COOKIES
.rgpd-consent {
    z-index: 9995;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    min-height: 250px;
    color: #fff;
    background-color: fadeout(@color, 2%);
    .flex(column,center,center);

    p {
        padding: 0 15px;
        text-align: center;
        margin-bottom: 20px;
    }

    .rgpd-list {
        p {
            text-align: left;
        }
    }

    h5 {
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
    }

    label {
        color: #fff;
    }

    form {
        text-align: center;
    }

    .btn {
        margin: 0 5px;
        margin-bottom: 15px;
    }

    .rgpd-no-consent {
        color: #fff;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }

    .cols {
        justify-content: center;

        @media @mobile {
            flex-direction: column;
        }
    }
} // .rgpd-consent
