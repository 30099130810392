// BLOG - pages

// PAGES CMS
.article-list {
    margin-bottom: 50px;
    .grid-ar(1fr, 1fr 1fr 1fr, 30px);

    @media @laptop {
        grid-template-columns: 1fr 1fr;
    }

    @media @mobile {
        grid-template-columns: 1fr;
    }
}

.page {
    .main-content .heading {
        margin-bottom: 10px;
    }

    .txt-description {
        font-weight: 300;
        font-size: 1.8rem;
        text-align: center;
        padding: 0 15px;
        max-width: 500px;
        margin: auto;
        margin-bottom: 25px;
    }
}

.bloc {
    background-color: #fff;
    padding: 30px 20px;
    margin-bottom: 40px;
    .rounded(15px);
    .box-shadow(0, 0, 15px, 0.09);

    .h5 {
        display: block;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: @color;
    }
}

.articles {
    .filters {
        background-color: #efefef;
        padding: 24px 15px;
        margin-bottom: 40px;
    }
}

.search-form {
    .flex(row,center,center);

    @media @xmobile {
        flex-direction: column;
    }

    input {
        margin: 0 5px;
    }

    input[type="search"] {
        min-width: 300px;

        @media @xmobile {
            margin-bottom: 10px;
        }
    }
}

.head-article {
    margin-bottom: 30px;
    .flex();

    @media @laptop {
        flex-direction: column-reverse;
    }

    figure {
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;
        width: 500px;
        min-width: 500px;
        height: 250px;
        background-color: @grey-light;
        .rounded(15px);

        @media @mobile {
            min-width: 0;
            height: auto;
            width: auto;
            margin: auto;
            margin-bottom: 30px;
        }

        img {
            max-width: 100%;
            display: block;
        }
    }

    article {
        flex-grow: 1;
        margin-left: 30px;
        text-align: center;
    }

    .h2 {
        margin-bottom: 20px;
    }

    .info-pub {
        font-size: 1.8rem;
        font-family: @font2;
        letter-spacing: 0.08rem;
        text-transform: uppercase;
        color: @color;
        margin-bottom: 20px;
    }
} // .head-article

.item-article {
    position: relative;
    max-width: 418px;
    padding-bottom: 30px;
    background-color: #fff;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    margin-bottom: 22px;
    justify-self: center;
    .box-shadow(0, 5px, 10px, 0.05);
    .flex(column,stretch,flex-start);
    .transition;

    .art-figure {
        figure {
            background-color: @grey-light;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            min-height: 305px;
            .transition;
        }

        &:hover {
            figure {
                opacity: 0.6;
            }
        }
    }

    .infos-art {
        min-height: 34px;
        color: #fff;
        padding: 0 35px;
        background-color: @color3;
        margin-bottom: 25px;
        font-size: 1.4rem;
        text-transform: uppercase;
        .flex();
    }

    .date {
        font-weight: 500;
        font-style: italic;
    }

    .cat-link {
        color: #fff;
        font-family: @font2;
        font-size: 1.6rem;

        &:hover {
            opacity: 0.6;
        }
    }

    .h5 {
        padding: 0 35px;
        margin-bottom: 20px;
        text-transform: uppercase;
        .flex(column,flex-start,flex-start);

        a {
            color: @color2;
            margin-bottom: 20px;
        }

        &::after {
            content: '';
            background-color: @grey;
            height: 1px;
            width: 65px;
        }
    }

    .art-resume {
        padding: 0 35px;
        color: @grey;
        min-height: 110px;
    }

    .btn-more {
        position: absolute;
        left: 50%;
        bottom: -22px;
        margin-left: -22px;
        width: 44px;
        .rounded(10px);
    }
} // .item-article
