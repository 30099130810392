/////// GENERAL /////
html {
    font-size: 62.5%;
}

body {
    background: #fff url("../img/bg-page.jpg") center bottom no-repeat;
    font-size: 1.5rem;
    line-height: 1.4;
    font-family: @font;
    color: @fontcolor;
    min-height: 100vh;
    .flex(column, stretch, space-between);

    .main-content {
        padding-bottom: 40px;
        flex-grow: 1;
    }
}

.logo {
    img {
        max-width: 100%;
    }
}

// TOOLS
.clear {
    clear: both;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

.anchor {
    padding: 30px 0;
}

a {
    color: @color;
    text-decoration: none;
    .transition;

    @media print {
        text-decoration: underline;
    }
}

strong,
b {
    font-weight: 700;
}

abbr {
    border-bottom: 1px dotted #555;
}

sup {
    vertical-align: super;
    font-size: 0.8em;
}

figure {
    img {
        display: block;
        max-width: 100%;
    }
}

.txt {
    color: @grey;
    font-weight: 300;
    margin-bottom: 20px;
}

.legend {
    text-align: center;
    color: @grey;
    font-style: italic;
    padding: 20px 15px;
}

.blur {
    opacity: 0.4;
}

.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1180px;
    position: relative;

    @media @desktop-wide {
        padding: 0 10px;
    }

    @media @desktop {
        //max-width: 1000px;
    }

    @media @laptop {
        //max-width: 768px;
    }

    @media @tablet {
        //max-width: 640px;
    }

    @media @mobile {
        //max-width: none;
    }
}

/// LAYOUT ///
.cols {
    display: flex;
    justify-content: space-between;

    .col2 {
        width: 49%;
    }

    .col3 {
        width: 32%;
    }
}

// HEADINGS
.heading {
    i {
        margin-bottom: 8px;
    }

    .h2 {
        margin-bottom: 5px;
        text-align: center;
    }

    .h3 {
        font-size: 2.1rem;
        margin-bottom: 10px;
        text-transform: uppercase;
        text-align: center;
    }
}

.h-deco {
    .flex(column,center,flex-start);

    &::after {
        font-size: 4rem;
        content: @icon-deco;
        .picto-font();
    }
}

h1,
h2,
.h2 {
    color: @color2;
    font-family: @font2;
    font-weight: 400;
    font-size: 3.5rem;
    text-transform: uppercase;
    line-height: 1.1;
}

h3,
.h3 {
    font-family: @font;
    font-size: 1.8rem;
    font-weight: 300;
    text-transform: none;
    line-height: 1.1;
}

h4,
.h4 {
    font-family: @font2;
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: 300;
    line-height: 1.1;
}

h5,
.h5 {
    font-family: @font;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 1.1;
}

// DECO STUFF

.bg-losange {
    position: relative;

    &::before {
        position: relative;
        z-index: 2;
    }

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        .picto-font();

        content: @icon-losange;

        .empty& {
            content: @icon-losange-empty;
        }
    }

    &:not(.empty) {
        &::before {
            color: #fff;
        }
    }
} // .bg-losange

// SOCIAL NETWORKS
.social {
    .flex(row,center,flex-start);

    a {
        font-size: 2.6rem;
        margin-right: 10px;

        /* stylelint-disable */
        each(@socials, {
            &.@{key} {
                &:hover {
                    color: @value;
                }
            }
        })
        /* stylelint-enable */
    }
} // .social

// 404
.er404 {
    background-position: center;
    text-align: center;
    .flex(column,center,center);

    > * {
        margin: 10px 15px;
    }

    figure {
        max-width: 150px;
    }

    .btn {
        margin: 0;
    }
} // .er404

.outdated-browser {
    padding: 20px 0;
    text-align: center;
    background-color: @color2;

    .wrap {
        max-width: 800px;
    }

    h3,
    p {
        margin-bottom: 20px;
    }

    nav {
        .flex(row,center,center);

        a:not(:first-child) {
            margin-left: 15px;
        }
    }
} // .outdated-browser

#cookiebanner {
    z-index: 9999!important;
}

#netreviewsWidgetNum8333widget03NBav {
    display: none !important;
}

#map {
    min-height: 300px;
}
