/////// FOOTER //////

footer {
    @media print {
        display: none;
    }
} // footer

.bloc-contact {
    background-color: @color;
    color: #fff;
    padding: 25px 15px;

    .wrap {
        max-width: 800px;
    }

    .h5 {
        display: block;
        text-align: center;
        font-size: 2.1rem;
        color: @color3;
        margin-bottom: 10px;
        font-weight: 500;

        & + p {
            text-align: center;
            margin-bottom: 25px;
        }
    }

    label {
        color: #fff;
    }

    input:not(.btn),
    textarea {
        width: 100%;
    }
}

.form-contact {
    .grid(1fr, 1fr 1fr, 40px);

    @media @tablet {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

.main-footer {
    padding: 60px 15px 30px 15px;
    background-color: @grey-light;
}

.nav-footer {
    margin-bottom: 40px;
    .grid(1fr, 1fr 1fr 266px 266px 1fr,0);

    @media @laptop {
        grid-template-columns: repeat(4, 1fr);
    }

    @media @tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    @media @xmobile {
        grid-template-columns: 1fr;
    }

    .item-bloc {
        border-left: 1px solid #bbb;
        padding-left: 30px;

        @media @laptop {
            padding: 0 15px;
        }

        @media @tablet {
            padding: 0;
            margin-bottom: 35px;
            border-left: 0 none;
        }

        &:first-child {
            border: 0 none;
            padding-left: 0;
        }
    }

    .label-info {
        flex-wrap: wrap;
        .flex(row,center,flex-start);

        img {
            margin: 5px;
            max-width: 180px;
        }
    }

    .logo {
        margin-top: -20px;

        @media @laptop {
            display: none;
        }
    }

    .h2 {
        display: block;
        margin-bottom: 30px;
        font-size: 1.8rem;

        @media @tablet {
            margin-bottom: 15px;
        }
    }

    a {
        display: block;
        color: @grey;

        &:hover {
            color: @color;
        }
    }

    p {
        color: @grey;
        margin-bottom: 20px;
        padding-right: 20px;
    }
}

.footer-mentions {
    background-color: #fff;
    padding: 25px 5px;
    .flex(row,center,center);

    @media @xmobile {
        flex-direction: column;
    }

    p {
        flex-grow: 1;
        max-width: 440px;
        padding: 0 15px;

        @media @xmobile {
            padding-top: 15px;
            text-align: center;
        }
    }
}

.colophon {
    background-color: @color2;
    min-height: 40px;
    padding: 15px 0;
    color: #fff;
    .flex(row,center,center);

    p {
        text-align: center;
    }

    a {
        color: #fff;

        &:hover {
            color: @color3;
        }
    }
}
