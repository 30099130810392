// CMS STYLES - USE FOR CKEDITOR BO  - FRONT

.ck {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 20px;

    h2 {
        color: @color;
        margin-bottom: 20px;
        .h5;

        strong {
            font-weight: 400;
        }
    }

    p {
        font-weight: 400;
        margin-bottom: 15px;
        text-align: justify;
    }

    em {
        font-style: italic;
    }

    b,
    strong {
        font-weight: 900;
    }

    a:not(.btn) {
        &:hover {
            color: @color3;

            > img {
                opacity: 0.6;
            }
        }
    }

    .btn {
        display: inline-flex;
        margin: auto;
        padding: 0 10px;
        margin-bottom: 20px;
        background-color: @color;
        color: #fff;
        min-height: 30px;
        .rounded(4px);
    }

    .pres {
        font-family: @font;
        font-size: 2.2rem;
        color: @black;
        position: relative;

        &::before {
            font-style: normal;
            font-size: 4rem;
            position: absolute;
            color: @color2;
            content: "\00201c";
            top: -15px;
            left: -20px;
            z-index: -1;
        }
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    ul {
        margin-bottom: 30px;
        padding-left: 10px;
        border-left: 2px solid fadeout(@color, 40%);

        li {
            color: @color;
            font-style: italic;
            text-align: left;
        }
    }

    table {
        margin-bottom: 25px;
        width: 100%;

        tr {
            background-color: tint(@color, 91%);

            &:nth-child(odd) {
                background-color: tint(@color2, 80%);
            }

            td {
                padding: 10px;
                vertical-align: middle;
                text-align: left;

                h4 {
                    color: @color;
                }

                p {
                    max-width: 200px;
                    text-align: left;
                }

                > strong {
                    font-size: 1.6rem;
                    color: @color;
                }

                img {
                    margin: 0 5px;
                }
            }
        }
    }
} // .ck
