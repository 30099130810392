//////// FONTS

@font-face {
    font-family: 'Rakesly';
    src:
        local('RakeslyRg-Regular'),
        url('../fonts/rakesly-rg.woff2') format('woff2'),
        url('../fonts/rakesly-rg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rakesly';
    src:
        local('RakeslyEl-Regular'),
        url('../fonts/rakesly-el.woff2') format('woff2'),
        url('../fonts/rakesly-el.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '@{picto-font}';
    src: url('../fonts/@{picto-font}.woff?@{hash-icomoon}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/// PICTO FONT ///

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

[class^="icon-"],
[class*=" icon-"],
.picto-font {
    font-family: '@{picto-font}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

.icon-raisons {
    &::before {
        content: @icon-raisons;
    }
}

.icon-ca {
    &::before {
        content: @icon-ca;
    }
}

.icon-call {
    &::before {
        content: @icon-call;
    }
}

.icon-chalandise {
    &::before {
        content: @icon-chalandise;
    }
}

.icon-equipe {
    &::before {
        content: @icon-equipe;
    }
}

.icon-france {
    &::before {
        content: @icon-france;
    }
}

.icon-play {
    &::before {
        content: @icon-play;
    }
}

.icon-redevance {
    &::before {
        content: @icon-redevance;
    }
}

.icon-surface {
    &::before {
        content: @icon-surface;
    }
}

.icon-temoignage {
    &::before {
        content: @icon-temoignage;
    }
}

.icon-deco {
    &::before {
        content: @icon-deco;
    }
}

.icon-eshop {
    &::before {
        content: @icon-eshop;
    }
}

.icon-losange-empty {
    &::before {
        content: @icon-losange-empty;
    }
}

.icon-losange {
    &::before {
        content: @icon-losange;
    }
}

.icon-more {
    &::before {
        content: @icon-more;
    }
}

.icon-prev {
    &::before {
        content: @icon-prev;
    }
}

.icon-next {
    &::before {
        content: @icon-next;
    }
}

.icon-select {
    &::before {
        content: @icon-select;
    }
}

.icon-go {
    &::before {
        content: @icon-go;
    }
}

.icon-star {
    &::before {
        content: @icon-star;
    }
}

.icon-warning {
    &::before {
        content: @icon-warning;
    }
}

.icon-search {
    &::before {
        content: @icon-search;
    }
}

.icon-check {
    &::before {
        content: @icon-check;
    }
}

.icon-close {
    &::before {
        content: @icon-close;
    }
}

.icon-facebook {
    &::before {
        content: @icon-facebook;
    }
}

.icon-twitter {
    &::before {
        content: @icon-twitter;
    }
}

.icon-instagram {
    &::before {
        content: @icon-instagram;
    }
}

.icon-youtube {
    &::before {
        content: @icon-youtube;
    }
}
