/////// HEADER //////

header {
    // set syle header here

    @media print {
        display: none;
    }

    @media @mobile {
        //position: sticky;
        //top: 0;
        //z-index: 1500;
    }
} // header

.header-top {
    background-color: @color;

    .wrap {
        height: 40px;
        .flex(row,flex-start,flex-end);

        @media @mobile {
            height: 60px;
        }

        form {
            align-self: center;
        }
    }
}

.btn-bloc {
    position: relative;
    z-index: 1250;
    .flex(row,flex-start,flex-end);

    span {
        min-height: 40px;
        color: #fff;
        .flex(row,center,center);
        .transition();

        @media @mobile {
            min-height: 60px;
        }
    }

    i {
        margin-left: 15px;
        height: 50px;
        min-width: 50px;
        position: relative;
        color: #fff;
        font-size: 2.4rem;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        .flex(row,center,center);
        .transition();

        @media @mobile {
            height: 70px;
        }

        @media @xmobile {
            margin-left: 10px;
        }
    }

    &.btn-eshop {
        margin-left: 90px;

        @media @tablet {
            margin-left: 40px;
        }

        i {
            background-color: @color3;
        }

        span {
            @media @xmobile {
                display: none;
            }
        }

        &:hover {
            span {
                color: @color3;
            }

            i {
                //background-color: @color4;
            }
        }
    }
} // .btn-bloc

.header-main {
    background-color: @grey-light;
    min-height: 80px;
    .flex(row,center,flex-start);

    @media @desktop {
        min-height: 0;
        padding: 30px 0;
    }

    @media @mobile {
        padding: 0;
    }

    .logo {
        position: absolute;
        top: -25px;
        left: 0;
        z-index: 1500;
        width: 190px;

        @media @desktop {
            top: -85px;
        }

        @media @laptop {
            top: -60px;
            left: 10px;
            width: 180px;
        }

        @media @mobile {
            width: 120px;
        }
    }

    .reassurance {
        justify-content: flex-start;
        align-items: center;
        padding-left: 220px;

        @media @desktop {
            padding-left: 210px;
            align-items: flex-start;
        }

        @media @laptop {
            padding-left: 175px;
            flex-wrap: nowrap;
        }

        @media @mobile {
            display: none;
        }

        li {
            flex-direction: row;

            @media @desktop {
                flex-direction: column;
            }

            @media @laptop {
                margin: 0 10px;
            }
        }

        span {
            font-size: 1.5rem;
            text-align: left;

            @media @desktop {
                text-align: center;
            }
        }

        i {
            margin-right: 5px;
            font-size: 1.8rem;
            color: @color3;

            &::before {
                height: 55px;
                min-width: 55px;
            }

            &::after {
                font-size: 5.5rem;
            }
        }

        strong {
            color: @color3;
        }

        .txt {
            display: none;
        }
    } // .reassurance-header
}

.main-nav {
    background-color: #fff;
    margin-bottom: 20px;
    .box-shadow(0,7px, 8px, 0.1);

    .home & {
        position: relative;
        z-index: 1200;
        margin-bottom: 0;
    }
}

.menu {
    .flex(row,center,flex-end);

    @media @desktop {
        justify-content: center;
    }

    @media @laptop {
        padding: 0;
        align-items: stretch;
        flex-direction: column;
        display: none;
    }

    &.showing-nav {
        @media @laptop {
            display: flex;
        }
    }

    a {
        .flex(row,center,flex-start);
    }

    // LEVEL 1
    li {
        position: relative;
        .flex(row,center,flex-start);

        @media @laptop {
            flex-direction: column;
        }

        &:first-child {
            &::before {
                display: none;
            }
        }

        &::before {
            display: block;
            content: "";
            height: 14px;
            width: 1px;
            background-color: #efefef;
            margin: 0;

            @media @laptop {
                display: none;
            }
        }

        > a {
            font-family: @font2;
            color: @color2;
            text-transform: uppercase;
            font-size: 1.7rem;
            letter-spacing: 0.09rem;
            padding: 0 12px;
            height: 58px;
            justify-content: center;

            @media @laptop {
                width: 100%;
                text-align: center;
            }

            &:hover {
                background-color: @color2;
                color: #fff;
            }
        }

        &:hover {
            .submenu {
                display: flex;
            }
        }
    }
} // .menu

.submenu {
    display: none;
    position: absolute;
    top: 58px;
    left: 4px;
    right: 0;
    z-index: 9990;
    background-color: fadeout(#fff, 10%);
    padding: 10px;
    border: 1px solid tint(@color2, 80%);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media @tablet {
        width: 100%;
        position: static;
        align-items: stretch;
        border: 0 none;
        background-color: transparent;
    }

    a {
        min-height: 30px;
        color: @color3;
        padding: 0 10px;
        .rounded(5px);

        @media @tablet {
            justify-content: center;
        }

        &:hover {
            background-color: @color3;
            color: #fff;
        }
    }
} // .submenu

.toggle-nav {
    display: none;
    cursor: pointer;
    position: relative;
    height: 60px;
    margin: auto;
    flex-grow: 1;

    @media @laptop {
        display: block;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -20px;
        display: block;
        width: 40px;
        height: 4px;
        background-color: @color;
        .rotate(0);
        .rounded(4px);

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 40px;
            height: 4px;
            background-color: @color;
            transform: translateY(-8px);
            .transition;
            .rounded(4px);
        }

        &::after {
            transform: translateY(0);
            top: 8px;
        }

        .menu-open& {
            background-color: #fff;

            &::before {
                .rotate(-45deg);
            }

            &::after {
                .rotate(45deg);
                .origin(28px -2px);
            }
        } // .menu-open
    }
} // .toggle-nav
