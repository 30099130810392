//// HOME PAGE //

.home-head {
    min-height: 620px;
    margin-bottom: 120px;
    background: transparent url('../img/bg-home-head.jpg') center no-repeat;
    background-size: cover;
    .flex(row,flex-end,flex-start);

    @media @tablet {
        min-height: 0;
        margin-bottom: 40px;
        padding-top: 80px;
    }

    .wrap {
        max-width: 1055px;
    }

    img {
        position: absolute;
        right: 30px;
        top: -30px;

        @media @desktop {
            right: 20px;
            max-width: 640px;
        }

        @media @laptop {
            top: 30px;
            right: 10px;
            max-width: 420px;
        }

        @media @tablet {
            top: -50px;
            right: 0;
            max-width: 330px;
        }

        @media @mobile {
            max-width: 300px;
            right: 50%;
            margin-right: -150px;
        }
    }

    article {
        max-width: 725px;
        background-color: fadeout(#fff, 20%);
        padding: 30px;

        @media @tablet {
            max-width: none;
        }

        @media @mobile {
            padding: 140px 15px 30px 15px;
            text-align: center;
        }
    }

    .h2 {
        color: @color;
        margin-bottom: 45px;
    }

    p {
        line-height: 1.7;
        font-family: @font2;
        font-size: 1.8rem;
        font-weight: 400;
        margin-bottom: 45px;
    }
} // .home-head

.naissance-projet {
    margin-bottom: 30px;
    padding-top: 30px;

    .heading {
        margin-bottom: 25px;
    }

    .wrap {
        align-items: flex-end;
        justify-content: center;

        @media @laptop {
            padding: 0 30px;
        }

        @media @tablet {
            flex-direction: column;
        }
    }

    .txt-fondateur {
        max-width: 760px;
        margin-bottom: 40px;

        @media @laptop {
            max-width: 500px;
        }

        @media @tablet {
            max-width: none;
            margin-bottom: 0;
        }
    }

    img {
        min-width: 250px;
    }

    p {
        margin-bottom: 25px;
        color: @grey;
    }

    strong {
        font-size: 1.8rem;
        font-weight: 400;
        color: @color2;
    }
} // .naissance-projet

.nous-rejoindre {
    margin-bottom: 130px;

    > .heading {
        margin-bottom: 0;
        margin-top: -40px;
    }

    > .cols {
        align-items: flex-start;
        justify-content: center;

        @media @laptop {
            flex-direction: column;
        }
    }
}

.detail-brochure {
    width: 100%;
    max-width: 570px;
    margin-bottom: 50px;

    p {
        color: @grey;
        margin-bottom: 20px;
    }

    figure {
        padding: 0 25px;

        &:not(.fc-is-open) {
            cursor: pointer;

            img {
                .transition;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
} // .detail-brochure

.why-us {
    margin-bottom: 40px;

    .reassurance {
        margin-top: -70px;
        margin-bottom: 25px;
        padding: 0 30px;
        .grid-ar(1fr, 1fr 1fr 1fr, 30px);

        @media @laptop {
            margin-top: 0;
            margin-bottom: 30px;
            padding: 0 15px;
        }

        @media @mobile {
            grid-template-columns: repeat(2, 1fr);
        }

        li {
            margin: 0 10px;
            .flex(column,center,flex-start);

            i {
                font-size: 2.8rem;
                margin-bottom: 13px;
            }

            span {
                font-weight: 400;
                text-transform: uppercase;
                font-size: 1.8rem;
                font-family: @font2;
                letter-spacing: 0.1rem;
            }

            br {
                display: block;
            }

            strong {
                font-weight: 400;
                display: inline;
                font-size: 2.5rem;
                color: @color4;
            }
        }
    }
} // .why-us

.five-reasons {
    margin-bottom: 40px;
    .grid(1fr, repeat(5, 1fr), 25px);

    @media @laptop {
        grid-template-columns: repeat(2, 1fr);
    }

    @media @mobile {
        grid-template-columns: 1fr;
    }
}

.item-reason {
    max-width: 200px;
    justify-self: center;
    .flex(column,stretch,space-between);

    .h4 {
        min-height: 100px;
        letter-spacing: 0.35rem;
        font-size: 2.2rem;
        .flex(column,center,center);
    }

    strong {
        font-weight: 400;
    }

    p {
        min-height: 124px;
        padding: 10px;
        color: @grey;
        background-color: #fff;
        text-align: left;
    }
} // .item-reason

.temoignages {
    margin-bottom: 65px;
    background: transparent url('../img/bg-temoignages.jpg') center no-repeat;
    background-size: cover;
    padding: 150px 15px;

    .bg-losange {
        height: 120px;
        width: 120px;
        .flex(row,center,center);

        &::after {
            color: @color3;
            font-size: 12rem;
        }

        &::before {
            font-size: 5rem;
        }
    }

    .heading {
        margin-top: -90px;
    }

    .wrap {
        max-width: 1280px;
        padding: 30px 40px;
        background-color: fadeout(#fff, 20%);
        .rounded(15px);
    }
} // .temoignages

.list-videos {
    .grid(1fr, repeat(3, 1fr), 30px);

    @media @laptop {
        grid-template-columns: 1fr;
    }
}

.item-video {
    position: relative;
    min-height: 270px;

    @media @laptop {
        min-height: 155px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .flex(row,center,center);

        &:hover {
            .icon-play {
                background-color: @color3;
            }
        }
    }

    .icon-play {
        color: #fff;
        background-color: @color;
        height: 60px;
        width: 60px;
        font-size: 2.6rem;
        .transition;
        .rounded(50%);
        .flex(row,center,center);
    }
}

.go-aventure {
    margin-bottom: 10px;
    .flex(column,center,flex-start);

    .heading {
        margin-bottom: 20px;
    }
}
