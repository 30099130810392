//////// FORMS  ///////
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="tel"],
input[type="url"],
textarea {
    appearance: none;
    color: @color2;
    padding: 0 15px;
    height: 35px;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: @font;
    border: 1px solid @grey-light;
    background-color: #fff;
    margin-bottom: 20px;
    .rounded(5px);
    .placeholder(@color2, 0.3);

    &:focus {
        outline: 0 none !important;
        color: @color;
        border-color: @color;
    }
}

input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}

input[type="number"] {
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

.cpville {
    div {
        flex-grow: 1;

        &:first-child {
            max-width: 100px;
            margin-right: 15px;
        }
    }
}

// INPUT & BUTTON à utiliser avec class .btn
input[type="submit"],
button {
    appearance: none;
    cursor: pointer;

    &:not(.btn) {
        background-color: transparent;
        border: 0 none;
    }

    &.btn {
        padding: 0 20px;

        &.small {
            padding-right: 8px;
        }
    }
}

textarea {
    resize: vertical;
    padding: 10px;
    min-height: 150px;
}

label,
.label {
    display: block;
    color: @grey;
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 10px;
}

progress {
    appearance: none;
    border: 0 none;
    max-width: 600px;
    width: 100%;
    height: 25px;
    overflow: hidden;
    .rounded(20px);
}

::-webkit-progress-bar {
    background-color: #eee;
    .rounded(20px);
}

::-ms-fill,
::-moz-progress-bar,
::-webkit-progress-value {
    background-color: @color;
}

////  CAPTCHA  /////
.captcha {
    margin-bottom: 20px;
    .flex(row,center,flex-start);

    figure {
        background-color: #fff;
        padding: 0 4px;
        margin-right: 10px;
        .rounded(5px);
        .flex(row,center,center);

        img {
            margin-right: 0;
        }
    }

    img {
        min-width: 85px;
        width: 85px;
        height: auto;
        margin-right: 10px;
    }

    input[type="text"] {
        max-width: 190px;
        margin-bottom: 0;
    }

    a {
        height: 40px;
        width: 40px;
        margin-right: 20px;
        .flex(row,center,center);
    }

    .reload {
        height: 30px;
        width: 30px;
        .transition;

        cursor: pointer;

        path {
            fill: @color3;
        }

        &:hover {
            .rotate(360deg);
        }
    }
} // captcha

////// BOUTONS CHECKBOX /////
.checkbox {
    position: relative;
    z-index: 1;

    label {
        display: flex;
        position: relative;
        cursor: pointer;

        &::before {
            content: '';
            height: 24px;
            width: 24px;
            min-width: 24px;
            background-color: #fff;
            margin-right: 10px;
            padding-right: 15px;
        }

        &::after {
            position: absolute;
            left: -1px;
            top: -1px;
            color: transparent;
            font-size: 2.7rem;
            .transition;
            .picto-font;

            content: @icon-check;
        }
    }

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        outline: none;

        &:checked + label {
            color: @color3;

            &::after {
                color: @color3;
            }
        }
    }

    &.tag {
        .flex(row,center,flex-start);

        label {
            padding: 0 10px;
            margin-bottom: 20px;
            min-height: 34px;
            background-color: #efefef;
            color: @fontcolor;
            .rounded(15px);
            .flex(row-reverse,center,flex-end);

            &::before {
                display: none;
            }

            &::after {
                position: static;
                font-size: 2rem;
                color: #bfbfbf;
                margin-right: 5px;
                content: @icon-close;
            }
        }

        input[type="checkbox"] {
            &:checked + label {
                background-color: @color3;
                color: #fff;

                &::after {
                    font-size: 2.7rem;
                    color: #fff;
                    content: @icon-check;
                }
            }
        }
    } // .tag
} // checkbox

.form-legend {
    font-size: 1.2rem;
    font-style: italic;
    color: #fff;
    text-align: left;
    display: block;
    margin-bottom: 15px;
}

.add-remove-toggle {
    .btn {
        &.remove {
            display: none;
        }
    }

    input[type="checkbox"] {
        display: none;

        &:checked {
            & + label {
                .btn {
                    &.remove {
                        display: flex;
                    }

                    &.add {
                        display: none;
                    }
                }
            }
        }
    }
} // .add-remove-toggle

///// LISTE DE BOUTONS RADIO
.list-radio {
    position: relative;
    z-index: 1;
    margin-bottom: 13px;

    &.inline {
        .flex(row,center,flex-start);
    }

    li {
        margin-bottom: 5px;

        .inline& {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }

    label {
        position: relative;
        display: flex;
        font-family: @font;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-right: 10px;
            background-color: #fff;
            .rounded(50%);
            .transition();
        }

        &::after {
            position: absolute;
            left: 6px;
            top: 6px;
            content: '';
            height: 12px;
            width: 12px;
            background-color: #fff;
            .rounded(50%);
            .transition();
        }
    }

    input[type="radio"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        .rounded(50%);

        &:checked + label {
            color: @color3;

            &::after {
                background-color: @color3;
            }
        }
    }
} // list-radio

///// SELECT CUSTOM ////
.habillage {
    height: 35px;
    width: 250px;
    max-width: 250px;
    margin-bottom: 20px;
    position: relative;
    background-color: #fff;
    border: 1px solid @grey-light;
    flex-grow: 1;
    overflow: hidden;
    .rounded(5px);

    &::after,
    .icon-select {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 3rem;
        height: 35px;
        width: 50px;
        color: @color2;
        z-index: 97;
        content: @icon-select;
        .flex(row,center,center);
        .picto-font;
    }

    select,
    .optgroup {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        appearance: none;
        background-color: transparent;
        text-overflow: '';
        border: 0 none;
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 700;
        color: @color2;
        padding: 0 55px 0 15px;
        z-index: 98;
    }
} // habillage

.form-theme {
    textarea {
        width: 100%;
    }

    .captcha input[type="text"] {
        @media @mobile {
            max-width: 140px;
        }
    }

    .form-grid {
        margin-bottom: 25px;
        .grid-ar(1fr, repeat(2,1fr), 25px);

        @media @mobile {
            grid-template-columns: 1fr;
        }

        .habillage {
            max-width: none;
            width: 100%;
            margin-bottom: 0;
        }

        input {
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.dropdown-js {
    border-left: 0 none;
    border-right: 0 none;

    &.gotop {
        .optgroup {
            border-top: 1px solid @grey-light;
            border-bottom: 0 none;
            bottom: 0;
            top: auto;
        }

        .icon-select {
            .origin(center,center);
            .rotate(180deg);
        }
    }

    &.open {
        overflow: visible;

        .icon-select {
            z-index: 152;
        }

        .optgroup {
            z-index: 150;
        }
    }

    .icon-select {
        z-index: 100;
    }

    .optgroup {
        padding: 0;
        right: 0;
        bottom: auto;
        border-left: 1px solid @grey-light;
        border-right: 1px solid @grey-light;
        border-bottom: 1px solid @grey-light;
        .flex(column,stretch,flex-start);
        .rounded(5px);
    }

    .option {
        padding: 0 55px 0 15px;
        min-height: 35px;
        background-color: #fff;
        .flex(row,center,flex-start);

        > * {
            margin: 0 3px;
        }
    }
}

.input-file {
    width: auto;
    max-width: 300px;

    label {
        cursor: pointer;
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 400;
    }

    i {
        font-size: 2.5rem;
        margin-right: 5px;
        font-weight: 300;
    }

    input {
        display: none;
    }

    .file-selected {
        font-weight: 700;
        font-size: 1.5rem;
        text-align: left;
        padding: 0;
        margin-bottom: 0;
        margin-top: 15px;
        position: relative;

        &:not(:empty) {
            background-color: tint(@color3, 70%);
            margin-bottom: 20px;
            padding: 10px;

            &::before {
                content: "Fichier sélectionné : ";
                display: block;
                font-style: italic;
                font-weight: 300;
                color: @black;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 15px;
                top: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 10px 12px;
                border-color: transparent transparent tint(@color3, 70%) transparent;
            }
        }
    } // .file-selected
} // .input-file

// CUSTOM FLATPICKR
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: @color3;
    border-color: @color3;
}
